<template>
    <AuthenticateWhatsapp />
</template>

<script>
import AuthenticateWhatsapp from '../../../components/whatsapp/AuthenticateWhatsapp.vue';

export default {
    components: {
        AuthenticateWhatsapp
    }
}
</script>